var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"promocodes-table"},[_c('h3',{class:[
      'black--text mb-4',
      {
        'text-h6': !_vm.$vuetify.breakpoint.mobile,
        'text-subtitle-1 font-weight-medium': _vm.$vuetify.breakpoint.mobile,
      },
    ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.history_promocodes"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.profilePromocodesGetter,"loading":_vm.profilePromocodesLoadingGetter,"items-per-page":-1,"mobile-breakpoint":800,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:`item.type`,fn:function({ item: { type } }){return [_vm._v(" "+_vm._s(_vm.getTypeText(type))+" ")]}},{key:`item.status`,fn:function({ item: { status } }){return [_vm._v(" "+_vm._s(_vm.getStatusText(status))+" ")]}},{key:`item.bonus`,fn:function({ item }){return [(item.currency)?_c('span',[_vm._v(" "+_vm._s(_vm.formatMoney({ value: item.bonus, currency: item.currency, }))+" ")]):_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.bonusUsed)+" | "+_vm._s(item.bonus)+" ")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }