import { Vue, Component } from "vue-property-decorator";
import { profileModule } from "@store/namespaces";
import { ProfileActions, ProfileGetters } from "@store/modules/profile/types";
import { PromocodeType, PromocodeStatus } from "@/types/promocode";
import { Currency } from "@/types/currency";
import { formatMoney, formatDate } from "@helpers";

@Component
export default class PromocodesTable extends Vue {
  @profileModule.Action("fetchAvailablePromocodes")
  private readonly fetchAvailablePromocodesAction!: ProfileActions["fetchAvailablePromocodes"];
  @profileModule.Getter("profilePromocodesLoading")
  private readonly profilePromocodesLoadingGetter!: ProfileGetters["profilePromocodesLoading"];
  @profileModule.Getter("profilePromocodes")
  private readonly profilePromocodesGetter!: ProfileGetters["profilePromocodes"];

  private get tableHeaders() {
    return [
      {
        value: "date",
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.date"),
        sortable: false,
      },
      {
        value: "code",
        text: this.$vuetify.lang.t("$vuetify.fields.promocode_name"),
        sortable: false,
      },
      {
        value: "type",
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.type"),
        align: "center",
        sortable: false,
      },
      {
        value: "bonus",
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.amount"),
        align: "center",
        sortable: false,
      },
      {
        value: "status",
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.status"),
        align: "center",
        sortable: false,
      },
    ];
  }

  private getTypeText(type: PromocodeType) {
    return this.$vuetify.lang.t(
      `$vuetify.promocode.type.${type.toLowerCase()}`
    );
  }

  private getStatusText(status: PromocodeStatus) {
    return this.$vuetify.lang.t(
      `$vuetify.promocode.status.${status.toLowerCase()}`
    );
  }

  private formatDate(value: string) {
    return formatDate(value, { showTime: true });
  }

  private formatMoney({
    value,
    currency,
  }: {
    value: number;
    currency: Currency;
  }) {
    return formatMoney({
      value,
      currency,
    });
  }

  private mounted() {
    this.fetchAvailablePromocodesAction();
  }
}
